import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/Home.vue';
import AccessDenied from '../views/AccessDenied.vue';
import store from './store';
import lazyLoadView from '@/router/lazyLoadView';
import get from 'lodash/get';

// const addEditTitle = path => () =>
//     get(store.state, path + '._new') === true ? 'common.add' : 'common.edit';
//

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/accessDenied',
        name: 'accessDenied',
        meta: { title: 'Access Denied' },
        component: AccessDenied
    },
    {
        path: '*',
        redirect: '/'
    },
    {
        path: '/invalidPaymentResponse',
        name: 'invalidPaymentResponse',
        meta: { title: 'Invalid Payment Response' },
        component: () =>
            lazyLoadView(
                import(
                    /* webpackChunkName: "register" */
                    './views/invalidPaymentResponse.vue'
                )
            )
    },
    {
        path: '/:grpCode/register/:planKey',
        component: () =>
            lazyLoadView(
                import(
                    /* webpackChunkName: "register" */ './views/gnrc/register/register.vue'
                )
            ),
        props: true,
        children: [
            {
                path: '',
                name: 'genericInstruction',
                meta: { langBar: true },
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "register" */ './views/gnrc/register/registerInstruction.vue'
                        )
                    )
            },
            {
                path: 'form',
                name: 'genericForm',
                meta: { langBar: true },
                beforeEnter: (to, from, next) => {
                    if (
                        from.name === 'genericInstruction' ||
                        from.name === 'genericReview'
                    ) {
                        next();
                    } else {
                        next(
                            `${to.params.grpCode}/register/${to.params.planKey}`
                        );
                    }
                },
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "register" */ './views/gnrc/register/registerForm.vue'
                        )
                    )
            },
            {
                path: 'review',
                name: 'genericReview',
                meta: { langBar: true },
                beforeEnter: (to, from, next) => {
                    if (
                        from.name === 'genericForm' ||
                        from.name === 'genericRegisterPaymentFail' ||
                        from.name === 'printPage'
                    ) {
                        next();
                    } else {
                        next(
                            `${to.params.grpCode}/register/${to.params.planKey}`
                        );
                    }
                },
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "register" */ './views/gnrc/register/registerReview.vue'
                        )
                    )
            },
            {
                path: 'payment',
                name: 'genericRegisterPayment',
                beforeEnter: (to, from, next) => {
                    const ref = get(
                        store.state,
                        'gnrcRegister.submission.sbmtRefNum'
                    );
                    next(
                        ref
                            ? undefined
                            : `${to.params.grpCode}/register/${to.params.planKey}`
                    );
                },
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "register" */ './views/gnrc/register/registerPayment.vue'
                        )
                    )
            },
            {
                path: 'downloadDemandNote',
                name: 'genericRegisterPaymentDemandNote',
                beforeEnter: (to, from, next) => {
                    if (from.name === 'genericRegisterPayment') {
                        next();
                    } else {
                        next(
                            `${to.params.grpCode}/register/${to.params.planKey}`
                        );
                    }
                },
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "register" */ './views/gnrc/register/registerPaymentDemandNote.vue'
                        )
                    )
            },
            {
                path: 'paymentFail',
                name: 'genericRegisterPaymentFail',
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "register" */ './views/gnrc/register/registerPaymentFail.vue'
                        )
                    )
            },
            {
                path: 'paymentSuccess',
                name: 'genericRegisterPaymentSuccess',
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "register" */ './views/gnrc/register/registerPaymentSuccess.vue'
                        )
                    )
            },
            {
                path: 'printForm',
                name: 'printPage',
                beforeEnter: (to, from, next) => {
                    if (
                        from.name === 'genericReview' ||
                        from.name === 'genericRegisterPaymentSuccess'
                    ) {
                        next();
                    } else {
                        next(
                            `${to.params.grpCode}/register/${to.params.planKey}`
                        );
                    }
                },
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "register" */ './views/gnrc/register/registerPrint.vue'
                        )
                    )
            }
        ]
    },
    {
        path: '/registerViewStatus',
        component: () =>
            lazyLoadView(
                import(
                    /* webpackChunkName: "register" */ './views/gnrc/register/registerViewStatus.vue'
                )
            ),
        props: true,
        children: [
            {
                path: '',
                name: 'genericRegisterViewStatus',
                meta: { langBar: true },
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "register" */ './views/gnrc/register/registerViewStatusInputRef.vue'
                        )
                    )
            },
            {
                path: 'verify',
                name: 'genericRegisterViewStatusVerify',
                meta: { langBar: true },
                beforeEnter: (to, from, next) => {
                    if (from.name === 'genericRegisterViewStatus') {
                        next();
                    } else {
                        next('/registerViewStatus');
                    }
                },
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "register" */ './views/gnrc/register/registerViewStatusVerify.vue'
                        )
                    )
            },
            {
                path: 'review',
                name: 'genericRegisterViewStatusReview',
                meta: { langBar: true },
                beforeEnter: (to, from, next) => {
                    if (
                        [
                            'genericRegisterViewStatusVerify',
                            'genericRegisterViewStatus',
                            'genericRegisterViewStatusPrintPage',
                            'genericRegisterSecondStepSubmission'
                        ].includes(from.name)
                    ) {
                        next();
                    } else {
                        next('/registerViewStatus');
                    }
                },
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "register" */ './views/gnrc/register/registerViewStatusReview.vue'
                        )
                    )
            },
            {
                path: 'secondStepSubmission',
                name: 'genericRegisterSecondStepSubmission',
                meta: { langBar: true },
                beforeEnter: (to, from, next) => {
                    if (
                        ['genericRegisterViewStatusReview'].includes(from.name)
                    ) {
                        next();
                    } else {
                        next('/registerViewStatus');
                    }
                },
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "register" */ './views/gnrc/register/registerViewStatusSecondStepSubmission.vue'
                        )
                    )
            },
            {
                path: 'payment',
                name: 'genericRegisterSecondStepSubmissionPayment',
                beforeEnter: (to, from, next) => {
                    const ref = get(
                        store.state,
                        'gnrcRegister.submission.sbmtRefNum'
                    );
                    next(ref ? undefined : '/registerViewStatus');
                },
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "register" */ './views/gnrc/register/registerPayment.vue'
                        )
                    )
            },
            {
                path: 'downloadDemandNote',
                name: 'genericRegisterSecondStepPaymentDemandNote',
                beforeEnter: (to, from, next) => {
                    if (
                        from.name ===
                        'genericRegisterSecondStepSubmissionPayment'
                    ) {
                        next();
                    } else {
                        next(
                            `${to.params.grpCode}/register/${to.params.planKey}`
                        );
                    }
                },
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "register" */ './views/gnrc/register/registerPaymentDemandNote.vue'
                        )
                    )
            },
            {
                path: 'printForm',
                name: 'genericRegisterViewStatusPrintPage',
                beforeEnter: (to, from, next) => {
                    if (from.name === 'genericRegisterViewStatusReview') {
                        next();
                    } else {
                        next('/registerViewStatus');
                    }
                },
                component: () =>
                    lazyLoadView(
                        import(
                            /* webpackChunkName: "register" */ './views/gnrc/register/registerPrint.vue'
                        )
                    )
            }
        ]
    },
    {
        path: '/:grpCode',
        component: () =>
            lazyLoadView(
                import(
                    /* webpackChunkName: "exam" */ './views/gnrc/exam/examList.vue'
                )
            ),
        props: true,
        meta: { langBar: true, viewAplyStsBtn: true }
    }
];

// function isEqualPath(p1 = '', p2 = '') {
//     if (p1 === '/' || p2 === '/') {
//         return p1 === p2;
//     }
//     return p1.indexOf(p2) === 0;
// }

export default async function createRouter() {
    Vue.use(VueRouter);
    const router = new VueRouter({
        mode: 'history',
        base: window.CONTEXT,
        routes
    });

    // router.beforeEach((to, from, next) => {
    // });

    // if (store.getters.hasProfile('dev', 'local', 'demo')) {
    //     router.addRoutes((await import('../router/demoRoutes')).default);
    // }

    return router;
}
