import pageLoader from '@/components/pageLoader.vue';

const lazyLoadView = function (asyncView) {
    const AsyncHandler = () => ({
        component: asyncView,
        loading: pageLoader
    });
    return Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            return h(AsyncHandler, data, children);
        }
    });
};

export default lazyLoadView;
