import Vue from 'vue';
import App from './App.vue';
import createRouter from './router';
import store from './store';
import Config from 'common-vue/Config';
import {
    messagePlugin,
    interceptRequest,
    getMessage,
    addMessages
} from './store/messageStore';
import ctxPlugin from './plugins/ctxPlugin';
import { instance, get } from '@/utils/ajax';

Vue.config.productionTip = false;

Config.set('ctx', window.CONTEXT);
Config.set('getMessage', getMessage);
Config.set('addMessages', addMessages);
Config.set('dateFnsTimeZone', '+08:00');
Config.set('dateInputUseConfigTimeZone', true);

function getLocale() {
    var param = Object.fromEntries(
        window.location.search
            .replace(/^\?/, '')
            .split('&')
            .map((s) => s.split('='))
    );
    if (param.locale && param.locale.match(/en|zh-HK|zh-CN/)) {
        return param.locale;
    }
    return 'en';
}

interceptRequest(instance);

(async function () {
    Vue.use(messagePlugin);
    Vue.use(ctxPlugin);

    const locale = getLocale();
    store.commit('message/setLocale', locale);
    store.dispatch('message/loadDateFnsLocale', locale);
    const result = await get('/api/init', { locale });
    store.commit('message/setMessages', result.dataMap.messages);
    if (result.success === true) {
        store.commit('setScrUser', result.dataMap.user);
        store.commit('setProfiles', result.dataMap.profiles);
        store.commit('setSystemParameters', result.dataMap.systemParameters);
    } else {
        store.commit('setScrUser', undefined);
    }

    const router = await createRouter();
    window.appRouter = router;
    window.ipeFormStore = store;

    new Vue({
        router,
        store,
        render: (h) => h(App)
    }).$mount('#app');
})();

const addMaximumScaleToMetaViewport = () => {
    const el = document.querySelector('meta[name=viewport]');

    if (el !== null) {
        let content = el.getAttribute('content');
        let re = /maximum-scale=[0-9.]+/g;

        if (re.test(content)) {
            content = content.replace(re, 'maximum-scale=1.0');
        } else {
            content = [content, 'maximum-scale=1.0'].join(', ');
        }

        el.setAttribute('content', content);
    }
};

const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

const checkIsIOS = () =>
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

if (checkIsIOS()) {
    disableIosTextFieldZoom();
}
