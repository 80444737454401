var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app",attrs:{"id":"app"}},[_c('header',{staticClass:"mt-4 header-wrap"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-center align-items-md-start"},[_c('div',{staticClass:"mr-3 flex-shrink-0 mt-md-2"},[_c('logoSvgImg',{staticClass:"logo"})],1),(_vm.showSiteAndLang)?_c('div',{staticClass:"mt-4 mt-md-0 flex-md-grow-1 w-100"},[_c('h1',{staticClass:"site-title text-md-right text-primary"},[_vm._v(" "+_vm._s(_vm.$t('portal.site.name'))+" ")]),(_vm.$route.meta.langBar)?_c('div',{staticClass:"text-right"},[(_vm.enableEn && _vm.showLang)?_c('button',{staticClass:"btn btn-link text-nowrap",class:{
                                'text-primary font-weight-bold':
                                    _vm.$store.state.message.locale === 'en',
                                'text-dark':
                                    _vm.$store.state.message.locale !== 'en'
                            },on:{"click":function($event){return _vm.changeLang('en')}}},[_vm._v(" Eng ")]):_vm._e(),(_vm.enableTc && _vm.showLang)?_c('button',{staticClass:"btn btn-link text-nowrap",class:{
                                'text-primary font-weight-bold':
                                    _vm.$store.state.message.locale === 'zh-HK',
                                'text-dark':
                                    _vm.$store.state.message.locale !== 'zh-HK'
                            },on:{"click":function($event){return _vm.changeLang('zh-HK')}}},[_vm._v(" 繁 ")]):_vm._e(),(_vm.enableSc && _vm.showLang)?_c('button',{staticClass:"btn btn-link text-nowrap",class:{
                                'text-primary font-weight-bold':
                                    _vm.$store.state.message.locale === 'zh-CN',
                                'text-dark':
                                    _vm.$store.state.message.locale !== 'zh-CN'
                            },on:{"click":function($event){return _vm.changeLang('zh-CN')}}},[_vm._v(" 简 ")]):_vm._e()]):_vm._e(),(_vm.$route.meta.viewAplyStsBtn)?_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-outline-primary2 text-nowrap px-4",on:{"click":_vm.viewApplicationStatus}},[_c('icon',{attrs:{"src":"search.svg"}}),_vm._v(" "+_vm._s(_vm.$t('portal.viewAplySts.title'))+" ")],1)]):_vm._e()]):_vm._e()])])]),_c('main',[_c('router-view',{on:{"set-show-site-and-lang":_vm.setShowSiteAndLang,"set-lang-ind":_vm.setLangInd,"change-lang":_vm.changeLang}})],1),_c('rootModal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }