import axios from 'axios';
import qs from 'qs';
import doFileDownload from 'common-vue/triggerFileDownloadFromBlob';
import { getMessage } from 'common-vue/message';

export const createInstance = () =>
    axios.create({
        baseURL: window.CONTEXT
    });

export const instance = createInstance();

instance.interceptors.response.use(
    function (response) {
        if (response.headers['content-disposition']) {
            return response;
        }
        return response.data ? response.data : response;
    },
    async function (err) {
        /* eslint-disable no-console */
        console.error('ajax error', err.response);
        if (
            err.response.status === 403 &&
            (!err.response.data || err.response.data.errorId === undefined)
        ) {
            if (window.ipeFormStore && err.config.showPvlPrompt !== false) {
                window.ipeFormStore.dispatch(
                    'rootModal/showError',
                    getMessage('error.insufficient.rights')
                );
            }
        }
        if (err.response && err.response.data) {
            let data = err.response.data;
            if (data.errorId) {
                console.error('server error with errorId: ', data.errorId);
            }
            err = data;
        }
        return {
            success: false,
            dataMap: {
                errors: { server: getMessage('error.server') },
                error: err
            }
        };
    }
);

export const get = (...args) => instance.get(...args);

export const post = (...args) => instance.post(...args);

export const formPost = (url, data, config, ...args) =>
    instance.post(
        url,
        qs.stringify(data),
        {
            headers: {
                'Content-Type':
                    'application/x-www-form-urlencoded;charset=utf-8'
            },
            ...config
        },
        ...args
    );

export const fileDownload = async (opt, filename) => {
    if (!opt.url) {
        throw new Error('Please provide url for download');
    }
    const response = await instance({
        method: 'POST',
        ...opt,
        ...{ responseType: 'blob' }
    });

    return doFileDownload(response, filename);
};

export const fileUpload = async (url, formData, opt) => {
    return instance.post(url, formData, {
        headers: { 'Content-Type': 'multipart-form-data' },
        ...opt
    });
};
