// import storage from '@/storage';
import { instance } from '@/utils/ajax';
import Config from 'common-vue/Config';

const state = {
    locale: 'en',
    messages: {},
    formCode: undefined,
    dateFnsLocale: undefined
};

const addMessages = (obj, override) => {
    Object.entries(obj).forEach(([k, v]) => {
        if (!state.messages[k] || override) {
            state.messages[k] = v;
        }
    });
};

const getMessage = (_key, ...args) => {
    let key = _key.replace(/^portal(\[\w+\])?\./, '');
    const msg =
        state.messages[`portal[${state.formCode || ''}].` + key] ||
        state.messages['portal.' + key] ||
        state.messages[key];
    if (!msg) {
        return key;
    }
    return args.reduce(
        (s, a, i) => s.replace(new RegExp(`\\{${i}\\}`, 'g'), a),
        msg
    );
};

function getDesp(model, name) {
    let result;
    const locale = state.locale;
    if (locale === 'zh-HK') {
        result = model[name + 'Tc'];
    }
    if (locale === 'zh-CN') {
        result = model[name + 'Sc'];
    }
    return result ? result : model[name + 'En'] || model[name];
}

function getDespNew(model, name, defaultValue = '') {
    if (!model) {
        return defaultValue;
    }
    const locale = state.locale;
    const en = model[name + 'En'];
    const tc = model[name + 'Tc'];
    const sc = model[name + 'Sc'];

    if (locale === 'en') {
        return en || tc || sc || defaultValue;
    } else if (locale === 'zh-HK') {
        return tc || sc || en || defaultValue;
    } else if (locale === 'zh-CN') {
        return sc || tc || en || defaultValue;
    } else {
        return defaultValue;
    }
}

async function getDateFnsLocale(locale) {
    if (locale === 'en') {
        return undefined;
    }
    if (locale === 'zh-HK') {
        locale = 'zh-TW';
    }

    const result = await import(
        /* webpackInclude: /(zh-TW|zh-CN)/ */
        /* webpackChunkName: "dateFnsLocale" */
        /* webpackMode: "lazy-once" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        'date-fns/locale/' + locale + '/index'
    );
    return result.default;
}

const getters = {
    getMessage: () => getMessage,
    getDesp: () => getDesp,
    getDespNew: () => getDespNew
};

Config.set('getDateFnsLocale', () => state.dateFnsLocale);

const actions = {
    async loadDateFnsLocale({ commit }, locale) {
        const dateFnsLocale = await getDateFnsLocale(locale);
        commit('setDateFnsLocale', dateFnsLocale);
    },
    async changeLocale({ dispatch, commit, state }, locale) {
        commit('setLocale', locale);
        let prefix = ['portal.'];
        if (state.formCode) {
            prefix.push(`portal[${state.formCode}]`);
        }
        const result = await instance.get('/api/messages', {
            params: { prefix: prefix.join(',') }
        });
        commit('setMessages', result);
        await dispatch('loadDateFnsLocale', locale);
    }
};

const mutations = {
    setMessages(state, messages) {
        state.messages = messages;
    },
    addMessages(state, messages) {
        state.messages = {
            ...state.messages,
            ...messages
        };
    },
    setLocale(state, locale) {
        state.locale = locale;
        // storage.setItem('locale', locale);
    },
    setDateFnsLocale(state, val) {
        state.dateFnsLocale = val;
    },
    setFormCode(state, val) {
        state.formCode = val;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

const messagePlugin = {
    install: async (Vue) => {
        Vue.prototype.$t = getMessage;
        Vue.prototype.$d = getDesp;
        Vue.prototype.$n = getDespNew;
        return true;
    }
};

function interceptRequest(instance) {
    instance.interceptors.request.use(
        function (request) {
            request.headers['Accept-Language'] = state.locale;
            return request;
        },
        function (error) {
            return Promise.reject(error);
        }
    );
}

export {
    getMessage,
    getDesp,
    getDespNew,
    messagePlugin,
    interceptRequest,
    addMessages
};
