import Deferred from '@/utils/deferred';
import isString from 'lodash/isString';

let alertDefer;

export default {
    namespaced: true,
    state: () => ({
        modalConfig: undefined
    }),
    actions: {
        showError(ctx, msg) {
            let opt;
            if (isString(msg)) {
                opt = {
                    show: true,
                    message: msg,
                    type: 'error',
                    modalType: 'alert'
                };
            } else {
                opt = {
                    show: true,
                    type: 'error',
                    modalType: 'alert',
                    ...msg
                };
            }
            ctx.commit('setModalConfig', opt);
            alertDefer = new Deferred();
            return alertDefer.promise;
        },
        resolve(ctx) {
            alertDefer.resolve();
            alertDefer = undefined;
            ctx.commit('setModalConfig', undefined);
        },
        reject(ctx) {
            alertDefer.reject();
            alertDefer = undefined;
            ctx.commit('setModalConfig', undefined);
        }
    },
    mutations: {
        setModalConfig(state, val) {
            state.modalConfig = val;
        }
    }
};
