import Vue from 'vue';
import Vuex from 'vuex';
// import matchPvlCodeList from '../store/matchPvlCodeList';
import { get } from '@/utils/ajax';
import rootModal from '../store/rootModal';
import message from './store/messageStore';

const store = {
    state: () => ({
        scrUser: undefined,
        profiles: undefined,
        systemParameters: undefined
    }),
    mutations: {
        setScrUser(state, val) {
            state.scrUser = val;
        },
        setProfiles(state, val) {
            state.profiles = val;
        },
        setSystemParameters(state, val) {
            state.systemParameters = val;
        }
    },
    getters: {
        hasProfile: (state) => (...profiles) => {
            return (state.profiles || []).some((v) => profiles.includes(v));
        },
        //should be used by back office only
        // hasPvl: (state) => (code) => {
        //     let privileges =
        //         state.scrUser && state.scrUser.userPrivileges
        //             ? state.scrUser.userPrivileges.springAuthorityStrings || []
        //             : [];
        //     return matchPvlCodeList(code, privileges);
        // },
        user(state) {
            return state.scrUser && state.scrUser.user;
        }
    },
    actions: {
        async reloadScrUser(ctx) {
            const scrUser = await get('/api/getUser');
            ctx.commit('setScrUser', scrUser);
        }
    },
    modules: {
        rootModal,
        message
    }
};

Vue.use(Vuex);

store.getters.isAdmin = () => false;

export default new Vuex.Store(store);
