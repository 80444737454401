<template>
    <div>
        <div class="container">
            <p>Welcome</p>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    methods: {}
};
</script>
