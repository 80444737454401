<style lang="scss">
@import 'style/portal/base';
.logo {
    height: 55px;
    width: 228px;
}
.site-title {
    font-size: $h5-font-size;
}
.header-wrap {
    border-bottom: 3px solid $gray-200;
}
@include media-breakpoint-up(md) {
    .site-title {
        font-size: $h4-font-size;
    }
    .header-wrap {
        border-bottom: none;
    }
}
</style>

<template>
    <div id="app" class="app">
        <header class="mt-4 header-wrap">
            <div class="container">
                <div
                    class="d-flex flex-column flex-md-row align-items-center align-items-md-start"
                >
                    <div class="mr-3 flex-shrink-0 mt-md-2">
                        <logoSvgImg class="logo" />
                    </div>
                    <div
                        class="mt-4 mt-md-0 flex-md-grow-1 w-100"
                        v-if="showSiteAndLang"
                    >
                        <h1 class="site-title text-md-right text-primary">
                            {{ $t('portal.site.name') }}
                        </h1>
                        <div class="text-right" v-if="$route.meta.langBar">
                            <button
                                class="btn btn-link text-nowrap"
                                :class="{
                                    'text-primary font-weight-bold':
                                        $store.state.message.locale === 'en',
                                    'text-dark':
                                        $store.state.message.locale !== 'en'
                                }"
                                @click="changeLang('en')"
                                v-if="enableEn && showLang"
                            >
                                Eng
                            </button>
                            <button
                                class="btn btn-link text-nowrap"
                                :class="{
                                    'text-primary font-weight-bold':
                                        $store.state.message.locale === 'zh-HK',
                                    'text-dark':
                                        $store.state.message.locale !== 'zh-HK'
                                }"
                                @click="changeLang('zh-HK')"
                                v-if="enableTc && showLang"
                            >
                                繁
                            </button>
                            <button
                                class="btn btn-link text-nowrap"
                                :class="{
                                    'text-primary font-weight-bold':
                                        $store.state.message.locale === 'zh-CN',
                                    'text-dark':
                                        $store.state.message.locale !== 'zh-CN'
                                }"
                                @click="changeLang('zh-CN')"
                                v-if="enableSc && showLang"
                            >
                                简
                            </button>
                        </div>
                        <div
                            class="text-right"
                            v-if="$route.meta.viewAplyStsBtn"
                        >
                            <button
                                class="btn btn-outline-primary2 text-nowrap px-4"
                                @click="viewApplicationStatus"
                            >
                                <icon src="search.svg" />
                                {{ $t('portal.viewAplySts.title') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <main>
            <router-view
                @set-show-site-and-lang="setShowSiteAndLang"
                @set-lang-ind="setLangInd"
                @change-lang="changeLang"
            ></router-view>
        </main>
        <rootModal />
    </div>
</template>

<script>
import logoSvgImg from '@/assets/ea_logo_lg.svg';
import rootModal from '@/components/rootModal.vue';
import icon from 'common-vue/icon';

export default {
    components: { rootModal, logoSvgImg, icon },
    data: () => ({
        showSiteAndLang: true,
        showLang: false,
        enableEn: true,
        enableTc: true,
        enableSc: true
    }),
    methods: {
        changeLang(lang) {
            this.$store.dispatch('message/changeLocale', lang);
        },
        setShowSiteAndLang(val) {
            this.showSiteAndLang = val;
            this.setShowLang(val);
        },
        setShowLang(val) {
            this.showLang = val;
        },
        setLangInd({ enableEn, enableTc, enableSc }) {
            this.enableEn = enableEn;
            this.enableTc = enableTc;
            this.enableSc = enableSc;
            const count = enableEn + enableTc + enableSc;
            if (count > 1) {
                this.setShowLang(true);
            }

            let { locale } = this.$store.state.message;

            if (locale === 'en' && !enableEn) {
                locale = enableTc ? 'zh-HK' : 'zh-CN';
            } else if (locale === 'zh-HK' && !enableTc) {
                locale = enableSc ? 'zh-CN' : 'en';
            } else if (locale === 'zh-CN' && !enableSc) {
                locale = enableTc ? 'zh-HK' : 'en';
            }
            this.changeLang(locale);
        },
        viewApplicationStatus() {
            let win = window.open(
                '',
                'viewStatus',
                'menubar=no,location=no,resizable=1,scrollbars=1'
            );
            win.location.href = `${window.CONTEXT}registerViewStatus?locale=${this.$store.state.message.locale}`;
        }
    }
};
</script>
