import { render, staticRenderFns } from "./rootModal.vue?vue&type=template&id=0f76e2ec&scoped=true&"
import script from "./rootModal.vue?vue&type=script&lang=js&"
export * from "./rootModal.vue?vue&type=script&lang=js&"
import style0 from "./rootModal.vue?vue&type=style&index=0&id=0f76e2ec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f76e2ec",
  null
  
)

export default component.exports