<style scoped>
.root-modal {
    z-index: 2050;
}
</style>
<template>
    <modal
        class="root-modal"
        :show="config.show"
        :showClose="config.showClose"
        :type="config.modalType"
        :okText="config.okText"
        :cancelText="config.cancelText"
        @ok="ok"
        @cancel="cancel"
    >
        <template #title v-if="config.title">{{ config.title }}</template>
        <template #content>
            <div :class="{ 'text-danger': config.type === 'error' }">
                {{ config.message }}
            </div>
        </template>
    </modal>
</template>

<script>
import modal from 'common-vue/modal';

export default {
    components: { modal },
    computed: {
        config() {
            return this.$store.state.rootModal.modalConfig || {};
        }
    },
    methods: {
        ok() {
            this.$store.dispatch('rootModal/resolve');
        },
        cancel() {
            this.$store.dispatch('rootModal/reject');
        }
    }
};
</script>
